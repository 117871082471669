export default {
  "global": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur Treebal Pro"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer une recherche"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
    "downloadQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le QR Code"])},
    "copyShortURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien court"])},
    "prompts": {
      "copiedToClipboard": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presse-papier"])},
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " a été envoyé dans votre presse-papier."])}
      },
      "loadingResourceError": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur chargement ressource"])},
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors du chargement de la ressource '", _interpolate(_named("name")), "'. Si le problème persiste veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
      }
    }
  },
  "navbar": {
    "titles": {
      "external": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"hidden-md\">Tribus</span> Publiques"])},
      "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"hidden-md\">Tribus</span> Corporate"])},
      "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
      "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation  ⧉"])}
    },
    "profile": {
      "my_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
      "smsCredits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédits SMS: "])}
    }
  },
  "external": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tribus Publiques"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des tribus"])},
    "firstTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez créer votre première tribu ?"])},
    "createFirstTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer ma première tribu"])}
  },
  "internal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tribus Corporate"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des tribus"])},
    "firstTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour commencer, définissez les membres et animateurs de votre organisation."])},
    "addMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des membres"])},
    "addAdmins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des animateurs"])}
  },
  "tribes": {
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle tribu"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une tribu dans le menu de gauche afin de visualiser les informations la concernant."])},
    "noTribes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez actuellement aucune tribu.</p><p> Vous pouvez en créer une en cliquant sur le bouton \"Nouvelle tribu\"."])},
    "types": {
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "discussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussion"])}
    }
  },
  "tribes-discussion": {
    "typeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussion"])},
    "modals": {
      "remove": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de la tribu"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tribu supprimée"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la suppression"])},
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de supprimer la tribu ", _interpolate(_named("name")), ". Voulez-vous continuer ?"])},
        "prompts": {
          "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La tribu ", _interpolate(_named("name")), " a été supprimée"])},
          "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la suppression de la tribu ", _interpolate(_named("name")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
        }
      }
    },
    "form": {
      "internal": {
        "edit": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Edition de la tribu ", _interpolate(_named("name"))])},
          "prompts": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification de tribu"])},
            "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La tribu ", _interpolate(_named("name")), " a bien été mise à jour."])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de modifier la tribu ", _interpolate(_named("name")), ". Si le problème persiste, veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        },
        "create": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle tribu"])},
          "prompts": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de tribu"])},
            "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La tribu ", _interpolate(_named("name")), " a bien été créée."])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de créer la tribu ", _interpolate(_named("name")), ". Si le problème persiste, veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        },
        "loadingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echec de chargement"])},
        "prompts": {
          "loadingFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de charger la tribu ", _interpolate(_named("name")), ". Si le problème persiste veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
        },
        "fields": {
          "name": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la tribu"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          },
          "avatar": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de la tribu"])},
            "explanation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionnez une image (", _interpolate(_named("maxHeight")), "x", _interpolate(_named("maxHeight")), ") dont le poids est inférieur à ", _interpolate(_named("sizeLimit")), "."])},
            "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévisualisation du logo."])}
          },
          "adminGroup": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adminGroup"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe d'animateurs"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet de limiter la gestion de la discussion à un groupe d'animateurs."])},
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racine"])}
          }
        }
      }
    },
    "members": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des membres de la tribu"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez actuellement pas de membre dans cette tribu."])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un membre"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le membre"])},
      "modals": {
        "remove": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du membre"])},
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre supprimé"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la suppression"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de supprimer le membre ", _interpolate(_named("email")), ". Voulez-vous continuer ?"])},
          "prompts": {
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le membre ", _interpolate(_named("email")), " a été supprimé"])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la suppression du membre ", _interpolate(_named("email")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        },
        "add": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ajouter des membres à la tribu ", _interpolate(_named("name"))])},
          "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres ajoutés"])},
          "addError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de membre echoué"])},
          "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de membres"])},
          "fields": {
            "userLists": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des membres de l'organisation"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
              "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuls les membres qui ont validé leur compte peuvent être ajoutés."])},
              "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])}
            }
          },
          "prompts": {
            "added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("succeed")), " membre(s) a(ont) été ajouté(s)."])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors l'ajout des membre à la tribu. Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        }
      }
    }
  },
  "tribes-information": {
    "typeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "modals": {
      "remove": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de la tribu"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tribu supprimée"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la suppression"])},
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de supprimer la tribu ", _interpolate(_named("name")), ". Voulez-vous continuer ?"])},
        "prompts": {
          "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La tribu ", _interpolate(_named("name")), " a été supprimée"])},
          "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la suppression de la tribu ", _interpolate(_named("name")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
        }
      }
    },
    "form": {
      "external": {
        "edit": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Edition de la tribu ", _interpolate(_named("name"))])},
          "prompts": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification de tribu"])},
            "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La tribu ", _interpolate(_named("name")), " a bien été mise à jour."])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de modifier la tribu ", _interpolate(_named("name")), ". Si le problème persiste veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        },
        "create": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle tribu"])},
          "prompts": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de tribu"])},
            "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La tribu ", _interpolate(_named("name")), " a bien été créée."])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de créer la tribu ", _interpolate(_named("name")), ". Si le problème persiste veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        },
        "loadingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echec de chargement"])},
        "prompts": {
          "loadingFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de charger la tribu ", _interpolate(_named("name")), ". Si le problème persiste veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
        },
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisation"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées"])},
        "fields": {
          "name": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la tribu"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          },
          "description": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de la tribu"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce texte sera visible sur la page détails de la tribu."])}
          },
          "privateResponse": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses privées"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les réponses privées"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En activant cette option, vous autorisez les membres de la tribu à répondre à vos publications (de manière privée)."])}
          },
          "searchable": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchable"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendre accessible à la recherche"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En activant cette option, vous donnez la possibilité à tous les utilisateurs de Treebal de trouver votre tribu et de s'y abonner."])}
          },
          "adminGroup": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adminGroup"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe d'animateurs"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet de limiter la gestion de la tribu à un groupe d'animateurs."])},
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racine"])}
          },
          "logo": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de la tribu"])},
            "explanation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionnez une image (", _interpolate(_named("maxHeight")), "x", _interpolate(_named("maxHeight")), ") dont le poids est inférieur à ", _interpolate(_named("sizeLimit")), "."])},
            "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévisualisation de l'image du logo."])}
          },
          "image": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image de couverture"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image de couverture de la tribu"])},
            "explanation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionnez une image (", _interpolate(_named("maxHeight")), "x", _interpolate(_named("maxWidth")), ") dont le poids est inférieur à ", _interpolate(_named("sizeLimit")), "."])},
            "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévisualisation de l'image de couverture."])}
          },
          "welcome": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message de bienvenue"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre message de bienvenue"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s’agit du premier message visible par tous les nouveaux membres de votre tribu dans votre fil d'actualité."])}
          },
          "address": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          },
          "postalCode": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre code postal"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          },
          "city": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre ville"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          },
          "country": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre pays"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          },
          "timeSlot": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires d'ouverture"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos horaires d'ouverture"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          }
        }
      },
      "internal": {
        "edit": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Edition de la tribu ", _interpolate(_named("name"))])},
          "prompts": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification de tribu"])},
            "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La tribu ", _interpolate(_named("name")), " a bien été mise à jour."])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de modifier la tribu ", _interpolate(_named("name")), ". Si le problème persiste veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        },
        "create": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle tribu"])},
          "prompts": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de tribu"])},
            "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La tribu ", _interpolate(_named("name")), " a bien été créée."])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de créer la tribu ", _interpolate(_named("name")), ". Si le problème persiste veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        },
        "loadingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echec de chargement"])},
        "prompts": {
          "loadingFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de charger la tribu ", _interpolate(_named("name")), ". Si le problème persiste veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
        },
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisation"])},
        "fields": {
          "name": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la tribu"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          },
          "description": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de la tribu"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce texte sera visible sur la page détails de la tribu."])}
          },
          "privateResponse": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses privées"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les réponses privées"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En activant cette option, vous autorisez les membres de la tribu à répondre à vos publications (de manière privée)."])}
          },
          "searchable": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchable"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendre accessible à la recherche"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En activant cette option, vous donnez la possibilité à tous les utilisateurs de Treebal de trouver votre tribu et de s'y abonner."])}
          },
          "adminGroup": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adminGroup"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe d'animateurs"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet de limiter la gestion de la tribu à un groupe d'animateurs."])},
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racine"])}
          },
          "logo": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de la tribu"])},
            "explanation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionnez une image (", _interpolate(_named("maxHeight")), "x", _interpolate(_named("maxHeight")), ") dont le poids est inférieur à ", _interpolate(_named("sizeLimit")), "."])},
            "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévisualisation de l'image du logo."])}
          },
          "image": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image de couverture"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image de couverture de la tribu"])},
            "explanation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionnez une image (", _interpolate(_named("maxHeight")), "x", _interpolate(_named("maxWidth")), ") dont le poids est inférieur à ", _interpolate(_named("sizeLimit")), "."])},
            "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévisualisation de l'image de couverture."])}
          },
          "welcome": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message de bienvenue"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre message de bienvenue"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s’agit du premier message visible par tous les nouveaux membres de votre tribu dans votre fil d'actualité."])}
          }
        }
      }
    },
    "publications": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des publications de la tribu"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez actuellement pas de publication pour cette tribu. Vous pouvez en ajouter une en cliquant sur le bouton \"Créer une publication\"."])},
      "createPublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une publication"])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmer votre envoi"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la publication"])},
      "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication ajoutée"])},
      "createError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'ajout de la publication"])},
      "status": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimée"])},
        "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prête"])},
        "to-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Attente"])},
        "scheduled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Programmée pour le ", _interpolate(_named("date"))])},
        "to-publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message publié avec succès"])}
      },
      "form": {
        "create": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une publication"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de la publication"])}
        },
        "fields": {
          "name": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la publication"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom à usage interne, visible uniquement des animateurs."])}
          },
          "content": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contenu"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédigez le contenu de votre publication ici..."])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonne pratique: Pour générer de l'engagement et renforcer les liens avec vos destinataires, demandez dans le message une réponse ou une réaction 👍"])}
          },
          "media": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le média de votre choix."])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez sélectionner un média téléchargeable depuis l'application. Les images seront visualisées."])},
            "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévisualisation du média"])}
          },
          "publishDate": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de publication"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date programmée de la publication"])},
            "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          }
        }
      },
      "modals": {
        "remove": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression d'une publication"])},
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication supprimée"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la suppression"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de supprimer la publication ", _interpolate(_named("title")), ". Voulez-vous continuer ?"])},
          "prompts": {
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La publication ", _interpolate(_named("title")), " a été supprimée."])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la suppression de la publication ", _interpolate(_named("title")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        },
        "publish": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication publiée"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de publier la publication ", _interpolate(_named("title")), ". Voulez-vous continuer ?"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echec de la publication"])},
          "prompts": {
            "published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La publication ", _interpolate(_named("title")), " a été publiée"])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la publication de ", _interpolate(_named("title")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        }
      },
      "prompts": {
        "added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La publication ", _interpolate(_named("title")), " a été ajoutée."])},
        "createError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de l'ajout de la publication ", _interpolate(_named("title")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
      },
      "reactions": {
        "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Totalité des réactions à la publication : ", _interpolate(_named("total"))])}
      }
    },
    "discussions": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "events": {
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre interlocuteur a quitté la conversation."])},
        "messageDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce message a été supprimé"])}
      }
    },
    "members": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un membre"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le membre"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des membres de la tribu"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez actuellement pas de membre dans cette tribu."])},
      "notRegistred": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("displayName")), " n’a pas encore activé son compte Treebal."])},
      "notOrgMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisateur externe de l'organisation"])},
      "modals": {
        "remove": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du membre"])},
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre supprimé"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la suppression"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de supprimer le membre ", _interpolate(_named("email")), ". Voulez-vous continuer ?"])},
          "prompts": {
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le membre ", _interpolate(_named("email")), " a été supprimé"])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la suppression du membre ", _interpolate(_named("email")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        },
        "add": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ajouter des membres à la tribu ", _interpolate(_named("name"))])},
          "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres ajoutés"])},
          "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de membres"])},
          "fields": {
            "userLists": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des membres de l'organisation"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
              "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuls les membres de l'organisation peuvent être ajoutés à la tribu."])}
            }
          },
          "prompts": {
            "added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("succeed")), " membre(s) a(ont) été ajouté(s)."])}
          }
        }
      }
    }
  },
  "organization": {
    "products": {
      "pro": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate"])}
      },
      "visibility": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])}
      }
    },
    "members": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
      "noresults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de résultats pour la recherche demandée."])},
      "addMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un membre"])},
      "importMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer des membres"])},
      "addGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un groupe"])},
      "codeCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relance des codes"])},
      "renewMemberCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer un nouveau code"])},
      "removeMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le membre"])},
      "alreadyConnected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Membre authentifié le ", _interpolate(_named("date"))])},
      "memberActualCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le code actuel"])},
      "externalUId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id:"])},
      "notify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier dans Treebal"])},
      "root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racine"])},
      "rootgroup": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des membres de l'organisation"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez actuellement pas de membres dans votre organisation. Vous pouvez en ajouter un ou plusieurs en cliquant sur le bouton \"Ajouter un membre\"."])}
      },
      "subgroup": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des membres du groupe"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez actuellement pas de membres dans ce groupe. Vous pouvez en ajouter un ou plusieurs en cliquant sur le bouton \"Ajouter un membre\"."])}
      },
      "filters": {
        "verified": {
          "labels": {
            "no-filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiche tous les membres"])},
            "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiche seulement les membres vérifiés"])},
            "unchecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiche seulement les membres non vérifiés"])}
          }
        }
      },
      "prompts": {
        "renewedMemberCode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le membre ", _interpolate(_named("email")), " a un nouveau code d'authentification \"", _interpolate(_named("registeringCode")), "\""])},
        "renewalCodeError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors du renouvellement du code d'authentification du membre ", _interpolate(_named("email")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
      },
      "modals": {
        "addMember": {
          "create": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un membre"])}
          },
          "edit": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de "])}
          },
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignez un email et/ou un numéro de téléphone comme méthode de contact du membre. En fonction de sa méthode de contact, le membre recevra un email, un SMS ou  une notification pour l'inviter à installer son compte pro."])},
          "memberAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre ajouté"])},
          "memberUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre mis à jour"])},
          "addingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'ajout du membre"])},
          "savingMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement du(des) membre(s)"])},
          "savedMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre(s) enregistré(s)"])},
          "fields": {
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email de contact"])},
            "emailHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format email standard."])},
            "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone mobile de contact"])},
            "mobileHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format international +33..."])},
            "externalUId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant unique"])},
            "externalUIdHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant unique du membre dans votre organization."])},
            "membersGroup": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de membres"])},
              "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'annuaire du membre sera limité aux membres du même groupe. Un membre à la racine accède à tous les membres de l'organisation."])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racine"])}
            }
          },
          "prompts": {
            "memberAdded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le membre ", _interpolate(_named("name")), " a été ajouté à votre organisation."])},
            "memberUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le membre ", _interpolate(_named("name")), " a été mis à jour."])},
            "addingError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de l'ajout du membre ", _interpolate(_named("name")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])},
            "memberExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet email ou téléphone a déjà été importé."])},
            "externalUIdExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet identifiant unique est déjà attribué à un autre membre."])},
            "emailExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet email est déjà attribué à un autre membre."])},
            "mobileExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce numéro est déjà attribué à un autre membre."])},
            "savedMembers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("succeed")), " membre(s) a(ont) été ajouté(s), ", _interpolate(_named("failed")), " exclu(s) car déjà existant(s)."])}
          }
        },
        "importMembers": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer des membres"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En fonction de sa méthode de contact, chaque membre importé recevra un email, un SMS ou  une notification pour l'inviter à installer son compte pro."])},
          "fields": {
            "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier CSV contenant les nouveaux membres"])},
            "fileHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier au format CSV (séparateur ; et sans \") doit contenir les emails et/ou téléphones (au format international +33...) des nouveaux membres et peser 2Mo maximum."])}
          },
          "prompts": {
            "fileIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier sélectionné n'est pas correctement formaté ou est vide."])},
            "fileContainsMembers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le fichier contient ", _interpolate(_named("count")), " membres."])}
          }
        },
        "deleteMember": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression d'un membre"])},
          "memberDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre supprimé"])},
          "removeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la suppression"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de supprimer le membre ", _interpolate(_named("email")), ". Voulez-vous continuer ?"])},
          "prompts": {
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le membre ", _interpolate(_named("email")), " a été supprimé de votre organisation."])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la suppression du membre ", _interpolate(_named("email")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        },
        "addMembersGroup": {
          "fields": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe"])},
            "nameHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe de membres"])},
            "hiddenMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres du groupe masqués entre eux dans l'annuaire"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un groupe de membres"])},
          "groupAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de groupe de membres"])},
          "prompts": {
            "groupAdded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le groupe de membres \"", _interpolate(_named("name")), "\" a été ajouté. "])},
            "groupExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe avec ce nom existe déjà."])},
            "addingError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de l'ajout du groupe de membres \"", _interpolate(_named("name")), "\". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          },
          "addingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de groupe de membres"])}
        },
        "editMembersGroup": {
          "fields": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe"])},
            "nameHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe de membres"])},
            "hiddenMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres du groupe masqués entre eux dans l'annuaire"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition d'un groupe de membres"])},
          "groupAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de groupe de membres"])},
          "prompts": {
            "groupAdded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le groupe de membres \"", _interpolate(_named("name")), "\" a été modifié. "])},
            "groupExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe avec ce nom existe déjà."])},
            "addingError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la modification du groupe de membres \"", _interpolate(_named("name")), "\". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          },
          "addingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de groupe de membres"])}
        },
        "deleteMembersGroup": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression d'un groupe de membres"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de supprimer le groupe de membre ", _interpolate(_named("name")), ". Les membres du groupe seront supprimés. Voulez-vous continuer ?"])},
          "adminsGroupDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de membres supprimé"])},
          "prompts": {
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le groupe de membres ", _interpolate(_named("name")), " a été supprimé de votre organisation."])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la suppression du groupe de membres ", _interpolate(_named("name")), ". "])}
          },
          "removeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la suppression"])}
        },
        "viewMembersGroup": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuaire des membres du groupe"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les membres du groupe auront accès à ces autres membres dans l'annuaire de l'organisation dans l'application."])},
          "hiddenMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce groupe a été configuré pour que les membres soient masqués entre eux dans l'annuaire. Pour modifier, il faut éditer le groupe."])}
        },
        "viewMembers": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuaire de "])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce membre a accès à ces autres membres dans l'annuaire de l'organisation dans son application."])}
        },
        "notify": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier un membre dans Treebal"])},
          "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le numéro de mobile du membre que vous souhaitez notifier. S’il a téléchargé Treebal, il recevra une notification dans l’application et pourra ajouter son compte pro d’un simple clic. S'il n'a pas encore Treebal, il recevra un SMS l'invitant à télécharger l'application."])},
          "memberNotified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification membre"])},
          "fields": {
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de mobile"])},
            "phoneHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il doit contenir l'indicatif pays, sans espace (+33600000000)"])},
            "phoneError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semblerait que le numéro saisi ne soit pas correct ou qu'il ne corresponde à aucun utilisateur connu."])}
          },
          "prompts": {
            "memberNotified": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le membre ", _interpolate(_named("name")), " a été notifié sur le numéro ", _interpolate(_named("phone")), "."])}
          }
        },
        "codeCampaign": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relance d'envoi des codes aux comptes non activés"])},
          "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour tous les membres qui n'ont pas encore activé leur compte, un message contenant le code va leur être ré-envoyé."])},
          "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon la méthode de contact du membre, un email, un SMS ou une notification contenant le code sera envoyé."])},
          "noCredits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas assez de crédits SMS pour réaliser la campagne. Merci de contacter le support Treebal (support", "@", "treebal.green)."])},
          "credits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'envoi de la campagne va consommer ", _interpolate(_named("sms")), " SMS dans votre crédit SMS : ", _interpolate(_named("smsCredits")), "."])},
          "conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une campagne est déjà en cours d'envoi."])},
          "unknownerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Merci de réessayer ultérieurement."])},
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relance des codes"])},
            "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'envoi des codes aux membres non activés a été réalisé."])}
          }
        },
        "memberTribes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements aux tribus d'information corporate de"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des tribus d'information corporate abonnées"])},
          "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
          "save": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des abonnements"])},
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des abonnements réalisée"])},
            "prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("succeed")), " abonnements mis à jour"])}
          }
        }
      },
      "mobileAppStatus": {
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application plus récente"])},
        "upToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application mobile à jour"])},
        "toUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application mobile obsolète"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de déterminer la version de l'application mobile"])}
      }
    },
    "admins": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animateur"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animateurs"])},
      "noresults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de résultats pour la recherche demandée."])},
      "rootgroup": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des animateurs de l'organisation"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez actuellement pas d'animateur dans votre organisation. Vous pouvez en ajouter un ou plusieurs en cliquant sur le bouton \"Ajouter un animateur\"."])}
      },
      "subgroup": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des animateurs du groupe"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez actuellement pas d'animateur dans ce groupe. Vous pouvez en ajouter un ou plusieurs en cliquant sur le bouton \"Ajouter un animateur\"."])}
      },
      "addAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un animateur"])},
      "removeAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'animateur"])},
      "modals": {
        "addAdmin": {
          "create": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un animateur"])}
          },
          "edit": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de "])}
          },
          "adminAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animateur ajouté"])},
          "adminUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animateur mis à jour"])},
          "addingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'ajout de l'animateur"])},
          "fields": {
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email de l'animateur"])},
            "emailHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email renseigné ne doit pas être celui d'un animateur existant."])},
            "adminsGroup": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe d'animateurs"])},
              "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un animateur affecté à un groupe ne pourra gérer que les animateurs du même groupe et les tribus affectées à ce groupe. Un animateur à la Racine pourra gérer tous les animateurs et toutes les tribus de l'organisation."])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racine"])}
            }
          },
          "prompts": {
            "adminAdded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'animateur ", _interpolate(_named("name")), " a été ajouté à votre organisation."])},
            "adminUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'animateur ", _interpolate(_named("name")), " a été mis à jour."])},
            "addingError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de l'ajout du membre ", _interpolate(_named("name")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])},
            "adminExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet email a déjà été importé."])}
          }
        },
        "deleteAdmin": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression d'un animateur"])},
          "adminDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animateur supprimé"])},
          "removeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la suppression"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de supprimer l'animateur ", _interpolate(_named("email")), ". Voulez-vous continuer ?"])},
          "prompts": {
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'animateur ", _interpolate(_named("email")), " a été supprimé de votre organisation."])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la suppression de l'animateur ", _interpolate(_named("email")), ". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          }
        },
        "addAdminsGroup": {
          "fields": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe"])},
            "nameHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe d'animateurs"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un groupe d'animateurs"])},
          "groupAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de groupe d'animateurs"])},
          "prompts": {
            "groupAdded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le groupe d'animateur \"", _interpolate(_named("name")), "\" a été ajouté. "])},
            "groupExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe avec ce nom existe déjà."])},
            "addingError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de l'ajout du groupe d'animateurs \"", _interpolate(_named("name")), "\". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          },
          "addingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de groupe d'animateurs"])}
        },
        "editAdminsGroup": {
          "fields": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe"])},
            "nameHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe d'animateurs"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition d'un groupe d'animateurs"])},
          "groupAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de groupe d'animateurs"])},
          "prompts": {
            "groupAdded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le groupe d'animateur \"", _interpolate(_named("name")), "\" a été modifié. "])},
            "groupExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe avec ce nom existe déjà."])},
            "addingError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la modification du groupe d'animateurs \"", _interpolate(_named("name")), "\". Veuillez contacter le support Treebal (support", "@", "treebal.green)."])}
          },
          "addingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de groupe d'animateurs"])}
        },
        "deleteAdminsGroup": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression d'un groupe d'animateurs"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de supprimer le groupe d'animateurs ", _interpolate(_named("name")), ".  Les animateurs du groupe seront supprimés. Voulez-vous continuer ?"])},
          "adminsGroupDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe d'animateurs supprimé"])},
          "prompts": {
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le groupe d'animateurs ", _interpolate(_named("name")), " a été supprimé de votre organisation."])},
            "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une erreur est survenue lors de la suppression du groupe d'animateurs ", _interpolate(_named("name")), ". "])}
          },
          "removeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la suppression"])}
        }
      },
      "addGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un groupe"])},
      "root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racine"])}
    },
    "prompts": {
      "limitedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d'essai"])},
      "limitedContent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous bénéficiez d'une offre d'essai pour tester Treebal Pro gratuitement jusqu'au ", _interpolate(_named("date")), ". Après cette date, vous pourrez choisir de continuer l'aventure en souscrivant un abonnement ou d'en rester là. Pour toute question, contactez-nous sur Treebal ou par mail (support", "@", "treebal.green)."])}
    },
    "adminsGroups": {
      "removeAdminsGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le groupe d'animateurs"])}
    }
  },
  "chat": {
    "rooms": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de discussion dans cette tribu."])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de discussions dans la tribu"])},
      "delete": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de discution réussi"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande de suppression de discution c'est bien réalisée."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de discussion échouée"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande de suppression de discussion n'a pu aboutir. Si le problème persiste veuillez contacter un animateur."])}
        }
      }
    }
  }
}